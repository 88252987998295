import { Component, OnInit } from '@angular/core';
import { basicConfig } from 'src/app/models/global.model';
import { ConfigService } from 'src/app/service/config.service';
import { WebViewInteropService } from 'src/app/service/web-view-interop.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-statusbar',
    templateUrl: './statusbar.component.html',
    styleUrls: ['./statusbar.component.css'],
    standalone: true,
    imports: [NgIf]
})
export class StatusbarComponent implements OnInit{
  config: basicConfig|undefined;

  constructor(private configService: ConfigService, private webViewInterop: WebViewInteropService){}

  ngOnInit(): void {
    this.config = this.configService.getBasicConfig();
  }

  openLink(url: string)
  {
    if(url.startsWith("http"))
    {
      this.webViewInterop.openLink(url);
    }
    else
    {
      this.webViewInterop.openLink("http://" + url);
    }

  }
}
