import { Component } from '@angular/core';
import { AvatarComponent } from '../avatar/avatar.component';
import { MenuComponent } from '../menu/menu.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: true,
    imports: [MenuComponent, AvatarComponent]
})
export class HeaderComponent {

  constructor() { }

}
